<template>
	<div v-if="CartStore.merchCrossSellProducts.length">
		<p class="my-2 text-xl font-semibold text-text">Don't forget these</p>
		<div class="flex w-full justify-between gap-4">
			<div
				v-for="product in CartStore.merchCrossSellProducts.slice(0, numberOfProducts)"
				:key="product.id"
				class="flex w-full items-center rounded bg-grey-very-light p-2"
			>
				<CartCrossSellProduct :product="product" />
			</div>
		</div>
	</div>

	<NuxtLink
		to="/checkout"
		:class="[
			'rounded-full bg-cta px-8 py-2 text-xl text-white transition hover:bg-cta-hover',
			'focus-visible:ring focus-visible:ring-primary',
		]"
		@click="CartStore.merchCrossSellOpen = false"
	>
		Proceed to Checkout
	</NuxtLink>
</template>

<script setup lang="ts">
import { useCartStore } from '../../stores/cartStore';
import CartCrossSellProduct from './CartCrossSellProduct.vue';
import useDetectWindowWidth from '../../composables/detectWindowWidth';

const CartStore = useCartStore();

const { windowWidth } = useDetectWindowWidth();

// If mobile device render 2 products else render 3
const numberOfProducts = computed(() => {
	if (windowWidth.value > 768) return 3;
	return 2;
});
</script>
