<template>
	<Transition
		name="slide"
		enter-active-class="transition duration-[275ms] ease-out"
		leave-active-class="transition duration-[400ms] ease-out"
		enter-from-class="transform translate-x-[350px] md:translate-x-[500px]"
		leave-to-class="transform translate-x-[350px] md:translate-x-[500px]"
	>
		<div v-show="isOpen" class="fixed left-0 top-0 z-50 h-screen w-screen">
			<div
				ref="focusTarget"
				class="absolute right-0 top-0 h-screen w-[85vw] overflow-y-scroll bg-white p-5 shadow-[-2px_2px_26px_rgba(0,0,0,0.3)] md:w-[400px]"
				@keydown.esc="emit('close')"
			>
				<button
					:tabindex="0"
					class="sticky top-0 z-50 -ml-1.5 mb-4 size-9 rounded-lg bg-white p-1 shadow-md focus-visible:rounded-sm focus-visible:outline focus-visible:outline-cta"
					@click="emit('close')"
					@keydown.enter="emit('close')"
				>
					<XMarkIcon />
				</button>
				<slot></slot>
			</div>
		</div>
	</Transition>
</template>

<script setup lang="ts">
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { onClickOutside } from '@vueuse/core';
import XMarkIcon from '@heroicons/vue/24/outline/XMarkIcon';

const props = defineProps<{ isOpen: boolean }>();
const emit = defineEmits<{ (eventName: 'close'): void }>();

const focusTarget = ref<HTMLElement | null>(null);
const { activate, deactivate } = useFocusTrap(focusTarget);
onClickOutside(focusTarget, () => emit('close'));
watch(
	() => props.isOpen,
	async (newValue) => {
		if (newValue) {
			await nextTick();
			activate();
		} else if (!newValue) {
			deactivate();
		}
	},
);
</script>
