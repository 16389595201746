import { defineStore, acceptHMRUpdate } from 'pinia';
import useDetectWindowWidth from '../composables/detectWindowWidth';
import handleFrontendError from '~/utils/handleFrontendError';
import type { ActiveBrandLink } from '~/types/app.types';

export const useHeaderStore = defineStore('Header', () => {
	const baseUrl = useRuntimeConfig().public.baseUrl;
	const mobileNavMenuOpen = ref(false);
	const brandLinks = ref<{ link: string; title: string }[]>([]);

	const { windowWidth } = useDetectWindowWidth();

	const isMobileOrTablet = computed(() => {
		if (windowWidth.value > 0) {
			if (windowWidth.value <= 1280) return true; // equivalent to tailwinds XL breakpoint
			return false;
		}
	});

	function openMenu() {
		mobileNavMenuOpen.value = true;
	}

	function closeMenu() {
		mobileNavMenuOpen.value = false;
	}

	async function fetchBrandLinks() {
		await $fetch<ActiveBrandLink[]>(`${baseUrl}/api/brands/active`, { cache: 'no-cache' })
			.then(
				(response) =>
					(brandLinks.value = response.map((brand) => ({
						link: '/' + brand.pageMetadata.slug,
						title: brand.name,
					}))),
			)
			.catch((error) => handleFrontendError(error, false));
	}

	return {
		brandLinks,
		fetchBrandLinks,
		mobileNavMenuOpen,
		isMobileOrTablet,
		openMenu,
		closeMenu,
		windowWidth,
	};
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useHeaderStore, import.meta.hot));
}
