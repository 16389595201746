<template>
	<div
		class="no-scrollbar flex max-h-[70vh] flex-col gap-2.5 overflow-auto md:grid md:grid-cols-3"
	>
		<div v-for="(item, i) in content" :key="item.image" class="space-y-2">
			<img :src="item.image" :alt="item.alt" class="w-full rounded-md" />
			<p class="text-left text-sm font-light text-text">
				<span class="font-medium">{{ i + 1 }}.</span> {{ item.text }}
			</p>
		</div>
	</div>
	<UiButtonOrderNow @click="emit('close')" />
</template>

<script setup lang="ts">
const emit = defineEmits<{ (eventName: 'close'): void }>();

const content = [
	{
		image: 'https://assets.weedys.com/storage/v1/object/public/Images/Curbside-Step-1.jpg',
		text: 'Browse our online shop and add items to your cart.',
		alt: 'Person browser the weedys.com shop on their mobile phone.',
	},
	{
		image: 'https://assets.weedys.com/storage/v1/object/public/Images/Curbside-Step-2.jpg',
		text: 'Checkout. After you place your order you will receive a confirmation text message.',
		alt: 'Person receiving confirmation message after placing their order on weedys.com.',
	},
	{
		image: 'https://assets.weedys.com/storage/v1/object/public/Images/Curbside-Step-3.jpg',
		text: 'Roll up, find a Weedys Express parking spot and click the link we texted you and we will bring your order out!',
		alt: 'Person picking up their weedys.com order curbside.',
	},
];
</script>
