<template>
	<svg
		id="Icon--Instagram"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 48 48"
		enable-background="new 0 0 48 48"
		xml:space="preserve"
	>
		<g>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M47.9,14.1c-0.1-2.6-0.5-4.3-1.1-5.8C46.1,6.7,45.3,5.4,44,4
		c-1.3-1.3-2.7-2.2-4.3-2.8c-1.5-0.6-3.3-1-5.8-1.1C31.3,0,30.5,0,24,0c-6.5,0-7.3,0-9.9,0.1c-2.6,0.1-4.3,0.5-5.8,1.1
		C6.7,1.9,5.4,2.7,4,4C2.7,5.4,1.9,6.7,1.3,8.3c-0.6,1.5-1,3.3-1.1,5.8C0,16.7,0,17.5,0,24c0,6.5,0,7.3,0.1,9.9
		c0.1,2.6,0.5,4.3,1.1,5.8C1.9,41.3,2.7,42.6,4,44c1.3,1.3,2.7,2.2,4.3,2.8c1.5,0.6,3.3,1,5.8,1.1C16.7,48,17.5,48,24,48
		c6.5,0,7.3,0,9.9-0.1c2.6-0.1,4.3-0.5,5.8-1.1c1.6-0.6,2.9-1.4,4.3-2.8c1.3-1.3,2.2-2.7,2.8-4.3c0.6-1.5,1-3.3,1.1-5.8
		C48,31.3,48,30.5,48,24C48,17.5,48,16.7,47.9,14.1z M43.5,33.7c-0.1,2.3-0.5,3.6-0.8,4.5c-0.4,1.1-1,1.9-1.8,2.8
		c-0.8,0.8-1.6,1.4-2.8,1.8c-0.8,0.3-2.1,0.7-4.5,0.8c-2.5,0.1-3.3,0.1-9.7,0.1c-6.4,0-7.2,0-9.7-0.1c-2.3-0.1-3.6-0.5-4.5-0.8
		c-1.1-0.4-1.9-1-2.8-1.8c-0.8-0.8-1.4-1.6-1.8-2.8C5,37.3,4.6,36,4.5,33.7c-0.1-2.5-0.1-3.3-0.1-9.7c0-6.4,0-7.2,0.1-9.7
		C4.6,12,5,10.7,5.3,9.8c0.4-1.1,1-1.9,1.8-2.8c0.8-0.8,1.6-1.4,2.8-1.8C10.7,5,12,4.6,14.3,4.5c2.5-0.1,3.3-0.1,9.7-0.1
		c6.4,0,7.2,0,9.7,0.1C36,4.6,37.3,5,38.2,5.3c1.1,0.4,1.9,1,2.8,1.8c0.8,0.8,1.4,1.6,1.8,2.8c0.3,0.8,0.7,2.1,0.8,4.5
		c0.1,2.5,0.1,3.3,0.1,9.7C43.7,30.4,43.7,31.2,43.5,33.7z"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M24,11.7c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3
		c6.8,0,12.3-5.5,12.3-12.3C36.3,17.2,30.8,11.7,24,11.7z M24,32c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8
		C32,28.4,28.4,32,24,32z"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M36.8,8.3c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9
		C39.7,9.6,38.4,8.3,36.8,8.3z"
			/>
		</g>
	</svg>
</template>
