<template>
	<div
		class="flex h-6 w-full items-center justify-center gap-4 md:h-7"
		:style="{ 'background-color': ThemeStore?.settings?.headerAccentColor ?? '#000000' }"
	>
		<NuxtLink
			to="/quick-order"
			no-prefetch
			:class="[
				'flex h-4 w-24 items-center justify-center rounded-full border-none text-xs font-semibold italic text-text',
				'bg-primary hover:bg-primary-hover',
				'md:h-5 md:w-28 md:text-sm',
				'focus-visible:ring-2 focus-visible:ring-white',
			]"
		>
			Quick Order
		</NuxtLink>

		<button
			:class="[
				'cursor-pointer text-xs font-light text-white underline underline-offset-2 md:text-sm',
			]"
			@click="modalOpen = true"
		>
			How to order
		</button>
	</div>
	<Teleport to="body">
		<UiModal :modal-open="modalOpen" @modal-close="modalOpen = false">
			<HeaderHowToOrder @close="modalOpen = false" />
		</UiModal>
	</Teleport>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useThemeStore } from '../../stores/themeStore';

const ThemeStore = useThemeStore();
const modalOpen = ref(false);
</script>
