<template>
	<svg id="icon--pin" viewBox="0 0 24 24">
		<path
			d="M12,20c-.15,0-.29-.04-.42-.13-.27-.18-6.58-4.52-6.58-8.87,0-3.79,3.21-7,7-7s7,3.21,7,7c0,4.35-6.31,8.69-6.58,8.87-.13,.09-.27,.13-.42,.13Zm0-14.5c-2.98,0-5.5,2.52-5.5,5.5,0,2.72,3.64,5.97,5.5,7.33,1.86-1.36,5.5-4.61,5.5-7.33,0-2.98-2.52-5.5-5.5-5.5Z"
		/>
		<path
			d="M12,14c-1.65,0-3-1.35-3-3s1.35-3,3-3,3,1.35,3,3-1.35,3-3,3Zm0-4.5c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5,1.5-.67,1.5-1.5-.67-1.5-1.5-1.5Z"
		/>
	</svg>
</template>
