<template>
	<SlideOutWrapper :is-open="HeaderStore.mobileNavMenuOpen" @close="emit('close')">
		<HeaderNav is-mobile="true" @close="emit('close')" />
	</SlideOutWrapper>
</template>

<script setup lang="ts">
import SlideOutWrapper from '../ui/SlideOutWrapper.vue';
import HeaderNav from './HeaderNav.vue';
import { useHeaderStore } from '../../stores/headerStore';

defineProps<{ menuOpen: boolean }>();

const emit = defineEmits(['close']);

const HeaderStore = useHeaderStore();
</script>
