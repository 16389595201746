<template>
	<header
		:style="{
			backgroundImage: `url(${ThemeStore.headerImage})`,
			backgroundColor: ThemeStore.settings.headerColor ?? '#171717',
		}"
		class="sticky top-0 z-50 flex flex-col items-center bg-neutral-900 bg-cover bg-center bg-no-repeat"
	>
		<!-- Header top -->
		<HeaderTopBar />

		<!-- Header main -->
		<div
			:class="[
				'flex w-full max-w-[1450px] items-center justify-between px-3 pb-2 pt-2 text-white',
				'lg:px-5 lg:py-2',
			]"
		>
			<div class="flex items-center gap-3">
				<!-- Mobile nav menu -->
				<button
					:tabindex="0"
					aria-label="Navigation menu"
					:class="[
						' -mt-0.5 size-8 cursor-pointer items-start fill-white stroke-2',
						'md:size-9 xl:hidden',
						'focus-visible:rounded-sm focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary',
					]"
					@click="HeaderStore.mobileNavMenuOpen = true"
				>
					<Bars3Icon />
				</button>

				<!-- Logo -->
				<NuxtLink
					v-if="ThemeStore.logo"
					to="/"
					no-prefetch
					class="focus-visible:rounded-sm focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary"
					@click="handleLogoClick"
				>
					<!-- Hard code default logo as perf improvement -->
					<img
						v-if="
							ThemeStore.logo ===
							'https://assets.weedys.com/storage/v1/object/public/Images/weedys-2024-logotype.png'
						"
						src="/public/weedys-2024-logotype.svg"
						alt="Weedys logo"
						class="h-9 w-28 md:h-10 md:w-32 lg:h-12 lg:w-40"
					/>
					<img
						v-else
						:src="ThemeStore.logo"
						alt="Weedys logo"
						class="h-9 w-auto md:h-10 lg:h-12"
					/>
				</NuxtLink>
			</div>

			<!-- Desktop nav -->
			<HeaderNav class="hidden xl:flex" />

			<!-- Cart/menu -->
			<div class="flex w-1/2 flex-row justify-end md:w-auto">
				<!-- Cart -->
				<HeaderCart
					:tabindex="0"
					:class="[
						'-mt-1 flex w-auto justify-end lg:-mt-0',
						HeaderStore.brandLinks.length ? 'lg:w-10' : 'lg:w-20',
						'focus-visible:rounded-sm focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary',
					]"
					@click="CartStore.cartOpen = true"
					@keydown.enter="CartStore.cartOpen = true"
				/>
			</div>
		</div>

		<!-- Filters -->
		<div class="flex w-full pb-1 lg:w-auto lg:justify-center lg:pb-2">
			<HeaderFilters />
		</div>

		<HeaderBottomBar />

		<HeaderNoticeBar v-if="ThemeStore.settings.noticeBarActive" />
	</header>
	<HeaderNavMobile
		v-if="HeaderStore.isMobileOrTablet"
		:menu-open="HeaderStore.mobileNavMenuOpen"
		@close="HeaderStore.mobileNavMenuOpen = false"
	/>
	<TheCart />
</template>

<script setup lang="ts">
import { useThemeStore } from '../stores/themeStore';
import { useCartStore } from '../stores/cartStore';
import { useHeaderStore } from '../stores/headerStore';
import { useProductsStore } from '~/stores/productsStore';
import Bars3Icon from '@heroicons/vue/24/outline/Bars3Icon';
import HeaderFilters from './header/HeaderFilters.vue';
import HeaderNoticeBar from './header/HeaderNoticeBar.vue';
import HeaderBottomBar from './header/HeaderBottomBar.vue';
import HeaderNav from './header/HeaderNav.vue';

const ThemeStore = useThemeStore();
const CartStore = useCartStore();
const HeaderStore = useHeaderStore();
const ProductsStore = useProductsStore();

const route = useRoute();

function handleLogoClick() {
	if (route.path === '/') {
		window.scrollTo(0, 0);
		ProductsStore.resetFilterOptions();
	}
}
</script>
