<template>
	<div
		:class="style"
		class="flex w-full justify-center px-3 py-0.5 text-center text-xs font-semibold md:text-base"
	>
		{{ ThemeStore.settings.noticeBarMessage }}
	</div>
</template>

<script setup lang="ts">
import { useThemeStore } from '~/stores/themeStore';
const ThemeStore = useThemeStore();

const style = computed(() => {
	if (ThemeStore.settings.noticeBarType === 'Announcement') return 'bg-cta text-white';
	if (ThemeStore.settings.noticeBarType === 'Notice') return 'bg-primary text-text';
	if (ThemeStore.settings.noticeBarType === 'Urgent') return 'bg-alert text-white';
	return '';
});
</script>
