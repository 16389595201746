<template>
	<div :style>
		<TheHeader />
		<NotificationProvider />
		<main id="main" class="mx-auto max-w-[1450px] p-5">
			<slot></slot>
		</main>
		<TheFooter />
	</div>
</template>

<script setup lang="ts">
import NotificationProvider from '../components/notifications/NotificationProvider.vue';
import { useProductsStore } from '../stores/productsStore';
import { useThemeStore } from '../stores/themeStore';
import { useHeaderStore } from '~/stores/headerStore';
import { onMounted } from 'vue';
import smoothScrollTo from '../utils/smoothScrollTo';

const ProductsStore = useProductsStore();
const ThemeStore = useThemeStore();
const HeaderStore = useHeaderStore();

callOnce(ProductsStore.fetchProducts);
callOnce(HeaderStore.fetchBrandLinks);
ThemeStore.init();
await callOnce(ProductsStore.fetchPriceTiers);

const route = useRoute();

const halloweenBackgroundStyle = `background-image: url('/halloween/graveyard-bg.jpg');
			background-attachment: fixed;
			background-position: bottom;
			background-repeat: no-repeat;
			background-size: cover;
			background-blend-mode: multiply;
			background-color: #413e42;`;
const style = computed(() => (route.path.startsWith('/halloween') ? halloweenBackgroundStyle : ''));

/** Detect url query parameter to use in pre-filtering the shop */
function filterShopBasedOnQuery() {
	if (route && route.name === 'index' && Object.keys(route.query).length > 0) {
		ProductsStore.resetFilterOptions();

		const category = route.query.category;
		const sort = route.query.sort;
		const lean = route.query.lean;
		const priceTier = route.query.tier;
		const brand = route.query.brand;
		const minPrice = route.query.minPrice;
		const maxPrice = route.query.maxPrice;

		let prefilterdShop = false;

		if (category && typeof category === 'string') {
			const replacedCategory = category.replace(/-/g, ' ');

			// Split the string into words
			const words = replacedCategory.split(' ');

			// Capitalize each word
			const capitalizedWords = words.map(
				(word) => word.charAt(0).toUpperCase() + word.slice(1),
			);

			// Join the words back together
			const capitalizedCategory = capitalizedWords.join(' ');

			ProductsStore.categoryUserSelection = capitalizedCategory;
			prefilterdShop = true;
		}

		if (sort) {
			if (sort === 'highest-thc' || sort === 'thc') {
				ProductsStore.sortByUserSelection = 'Highest THC';
				prefilterdShop = true;
			}
		}

		if (lean) {
			let query: string[] = [];
			switch (lean) {
				case 'sativa':
					query = ['Sativa', 'Sativa-Hybrid'];
					break;
				case 'hybrid':
					query = ['Hybrid'];
					break;
				case 'indica':
					query = ['Indica', 'Indica-Hybrid'];
					break;
			}
			if (query.length) {
				ProductsStore.leanUserSelection = query;
				prefilterdShop = true;
			}
		}

		if (priceTier && typeof priceTier === 'string') {
			const tier = priceTier.replace(/-/g, ' ');

			const pricingTier = ProductsStore.pricingTiers.filter(
				(el) => el.name.toLowerCase() === tier,
			);

			if (pricingTier.length) {
				ProductsStore.priceTierUserSelection = pricingTier[0].name;
				prefilterdShop = true;
			}
		}

		if (brand && typeof brand === 'string') {
			const brandName = brand.replace(/-/g, ' ');
			const foundBrand = Array.from(ProductsStore.brandOptions).filter(
				(brand) => brand.toLowerCase() === brandName,
			);
			if (foundBrand.length) {
				ProductsStore.brandUserSelection = foundBrand[0];
				prefilterdShop = true;
			}
		}

		if (minPrice) {
			ProductsStore.minPriceUserSelection = +minPrice;
			prefilterdShop = true;
		}

		if (maxPrice) {
			ProductsStore.maxPriceUserSelection = +maxPrice;
			prefilterdShop = true;
		}

		if (prefilterdShop) {
			setTimeout(() => {
				smoothScrollTo('#shop', 40);
			}, 300);
		}
	}
}

// Apply filters when the component is first loaded
onMounted(() => {
	ProductsStore.resetFilterOptions();
	filterShopBasedOnQuery();
});

// Apply filters when the route's query parameters change
watchEffect(() => filterShopBasedOnQuery());
</script>
