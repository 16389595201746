<template>
	<div
		class="absolute top-[30px] z-[100] flex flex-col gap-4 whitespace-nowrap rounded bg-white p-5 shadow-[2px_2px_14px_rgba(0,0,0,0.2)]"
	>
		<div v-for="(page, i) in pages" :key="page.link">
			<NuxtLink
				:to="page.link"
				no-prefetch
				class="text-text hover:text-text/80 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-1 focus-visible:outline-text"
				@click="if (HeaderStore.isMobileOrTablet) HeaderStore.closeMenu();"
				@blur="i === pages.length - 1 ? handleLastLinkBlur() : null"
				>{{ page.title }}</NuxtLink
			>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useHeaderStore } from '../../stores/headerStore';

interface Page {
	link: string;
	title: string;
}

const props = defineProps<{ pages: Page[]; onLastLinkBlur: () => void }>();

const HeaderStore = useHeaderStore();

function handleLastLinkBlur() {
	props.onLastLinkBlur();
}
</script>
