<template>
	<div class="-mt-0.5 flex w-full items-center gap-2 pl-3 lg:mt-0">
		<!-- Mobile shop filters -->
		<button
			v-if="route.path === '/'"
			:tabindex="0"
			:class="[
				'flex h-6 items-center justify-center gap-0.5 rounded-md border border-white pl-1 pr-2',
				'md:col-span-1 md:h-8 lg:hidden',
				'focus-visible:px-2 focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary',
			]"
			@click="shopFiltersOpen = !shopFiltersOpen"
		>
			<FilterSliders class="size-5 cursor-pointer stroke-white" />
			<p class="text-sm font-medium text-white">Filters</p>
		</button>

		<NuxtLink
			v-else
			no-prefetch
			:class="[
				'flex h-6 items-center justify-center gap-1 whitespace-nowrap rounded-md border border-white px-2',
				'md:col-span-1 md:h-8 lg:hidden',
				'focus-visible:px-2 focus-visible:outline focus-visible:outline-1 focus-visible:outline-primary',
			]"
			to="/#shop"
		>
			<p class="text-sm font-medium text-white">Go to Shop</p>
		</NuxtLink>

		<!-- Category buttons -->
		<div
			:class="[
				'no-scrollbar flex w-full gap-1.5 overflow-x-scroll rounded-l-md',
				'md:col-span-6 lg:col-span-7',
			]"
		>
			<button
				:ref="'button-' + filterOptions[0].name"
				:class="[
					'h-6 whitespace-nowrap rounded-md bg-primary px-2 text-sm text-text',
					'transition-all duration-300 hover:bg-zinc-200',
					'md:h-8 md:rounded-lg md:px-4',
				]"
				:style="{ width: buttonWidths[filterOptions[0].name] || 'auto' }"
				@click.prevent="handleFilterClick(filterOptions[0])"
			>
				<ArrowPathIcon
					v-if="loadingButton === filterOptions[0].name"
					class="size-4 animate-spin stroke-2 text-text"
				/>
				<span v-else>{{ filterOptions[0].name }}</span>
			</button>

			<button
				v-for="(option, i) in filterOptions.filter((option) => option.name !== 'Deals')"
				:key="option.name"
				:data-ref="'button-' + option.name"
				:class="[
					'h-6 whitespace-nowrap rounded-md bg-white px-2 text-sm text-text',
					'transition-all duration-300 hover:bg-zinc-200',
					'md:h-8 md:rounded-lg md:px-4',
					{ 'mr-3': i === filterOptions.length - 2 }, // account for deals option when subtracting
				]"
				:style="{ width: buttonWidths[option.name] || 'auto' }"
				@click.prevent="handleFilterClick(option)"
			>
				<ArrowPathIcon
					v-if="loadingButton === option.name"
					class="mx-auto flex size-3 animate-spin stroke-2 text-text"
				/>
				<span v-else>{{ option.name }}</span>
			</button>
		</div>
	</div>

	<SlideOutWrapper
		v-if="isMobileAndHomeRoute"
		class="lg:hidden"
		:is-open="shopFiltersOpen"
		@close="shopFiltersOpen = false"
	>
		<ShopFilters />
	</SlideOutWrapper>
</template>

<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import { useProductsStore } from '~/stores/productsStore';
import { useThrottleFn, useWindowSize } from '@vueuse/core';
import SlideOutWrapper from '../ui/SlideOutWrapper.vue';
import ShopFilters from '../shop/ShopFilters.vue';
import FilterSliders from '../icons/FilterSliders.vue';
import { ArrowPathIcon } from '@heroicons/vue/24/outline';
import handleFrontendError from '~/utils/handleFrontendError';

const route = useRoute();
const ProductsStore = useProductsStore();
const { width } = useWindowSize();
const shopFiltersOpen = ref(false);

// Used to show visual loading feedback to improve INP on slow devices/connections
const loadingButton = ref<string | null>(null);
const buttonWidths = ref<Record<string, string>>({});

interface FilterOption {
	name: string;
	url: string;
	callback: () => void;
}

const isHomeRoute = computed(() => route.path === '/');
const isMobileAndHomeRoute = computed(() => width.value < 1024 && isHomeRoute.value);

const filterOptions: FilterOption[] = [
	{
		name: 'Deals',
		url: '/?category=deals',
		callback: () => (ProductsStore.categoryUserSelection = 'Deals'),
	},
	{
		name: 'Packs',
		url: '/?category=bundle-pack',
		callback: () => (ProductsStore.categoryUserSelection = 'Bundle Pack'),
	},
	{
		name: 'Flower',
		url: '/?category=flower',
		callback: () => (ProductsStore.categoryUserSelection = 'Flower'),
	},
	{
		name: 'Concentrate',
		url: '/?category=concentrate',
		callback: () => (ProductsStore.categoryUserSelection = 'Concentrate'),
	},
	{
		name: 'Prerolls',
		url: '/?category=preroll',
		callback: () => (ProductsStore.categoryUserSelection = 'Preroll'),
	},
	{
		name: 'Ground',
		url: '/?category=ground',
		callback: () => (ProductsStore.categoryUserSelection = 'Ground'),
	},
	{
		name: 'Indica',
		url: '/?lean=indica',
		callback: () => (ProductsStore.leanUserSelection = ['Indica']),
	},
	{
		name: 'Sativa',
		url: '/?lean=sativa',
		callback: () => (ProductsStore.leanUserSelection = ['Sativa']),
	},
	{
		name: 'Hybrid',
		url: '/?lean=hybrid',
		callback: () => (ProductsStore.leanUserSelection = ['Hybrid']),
	},
	{
		name: 'Value',
		url: '/?tier=value',
		callback: () => (ProductsStore.priceTierUserSelection = 'Value'),
	},
	{
		name: 'Weed',
		url: '/?tier=weed',
		callback: () => (ProductsStore.priceTierUserSelection = 'Weed'),
	},
	{
		name: 'Good Weed',
		url: '/?tier=good-weed',
		callback: () => (ProductsStore.priceTierUserSelection = 'Good Weed'),
	},
	{
		name: 'Really Good Weed',
		url: '/?tier=really-good-weed',
		callback: () => (ProductsStore.priceTierUserSelection = 'Really Good Weed'),
	},
];

function gtmHeaderFilters(category: string) {
	try {
		useGtm()?.trackEvent({
			event: 'top-nav-filters',
			category: 'Shop',
			action: 'Shop Filter Selection',
			value: category,
			noninteraction: false,
		});
	} catch (error) {
		handleFrontendError(error, false);
	}
}

async function selectFilter(option: FilterOption) {
	try {
		loadingButton.value = option.name;
		ProductsStore.resetFilterOptions();

		if (isHomeRoute.value) {
			option.callback();
			scrollToShop();
		} else {
			option.callback();
			await navigateTo('/#shop');
		}

		gtmHeaderFilters(option.name);
		loadingButton.value = null;
	} catch (error) {
		handleFrontendError(error, false);
	}
}

const handleFilterClick = useThrottleFn((option: FilterOption) => selectFilter(option), 500);

function scrollToShop() {
	window.requestAnimationFrame(() => {
		const shopElement = document.getElementById('shop');
		if (shopElement) {
			shopElement.scrollIntoView({ behavior: 'smooth' });
		}
	});
}

onMounted(() => {
	filterOptions.forEach((option) => {
		const buttonRef = `button-${option.name}`;
		const buttonElement = document.querySelector(`[data-ref="${buttonRef}"]`);
		if (buttonElement) {
			const width = buttonElement.getBoundingClientRect().width + 'px';
			buttonWidths.value[option.name] = width;
		}
	});
});
</script>
